import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["selectAllButton", "productCheckbox", "errorContainer", "submitButton"];

  connect() {
    this.captureUTMParamsOnce();
    this.applyStoredUTMParamsToForm();
    this.captureIPAddress();
    this.captureReferrer();
    this.bindSectionButtons();
  }

  // Capture and append referrer
  captureReferrer() {
    const referrer = document.referrer;

    if (referrer && !localStorage.getItem("referrer")) {
      localStorage.setItem("referrer", referrer);
    }

    const form = document.querySelector("#customHubspotForm");
    if (form) {
      let referrerField = form.querySelector("input[name='referrer']");
      if (!referrerField) {
        referrerField = document.createElement("input");
        referrerField.type = "hidden";
        referrerField.name = "referrer";
        form.appendChild(referrerField);
      }
      referrerField.value = localStorage.getItem("referrer") || "";
    }
  }

  // Capture IP address and append it as a hidden field in the form
  captureIPAddress() {
    fetch("https://api.ipify.org?format=json")
      .then(response => response.json())
      .then(data => {
        const form = document.querySelector("#customHubspotForm");
        if (!form) return;

        let ipAddressField = form.querySelector("input[name='ipAddress']");
        if (!ipAddressField) {
          ipAddressField = document.createElement("input");
          ipAddressField.type = "hidden";
          ipAddressField.name = "ipAddress";
          form.appendChild(ipAddressField);
        }
        ipAddressField.value = data.ip;
      })
      .catch(error => console.error("Error fetching IP address:", error));
  }

  // Capture UTM parameters from URL and store in localStorage if not already stored
  captureUTMParamsOnce() {
    const urlParams = new URLSearchParams(window.location.search);
    ["utm_campaign", "utm_medium", "utm_source"].forEach(param => {
      const value = urlParams.get(param);
      if (value) localStorage.setItem(param, value);
    });

    // Capture HubSpot tracking cookie (hutk)
    let hutk = document.cookie.match(/hubspotutk=([a-zA-Z0-9-_]+);?/);
    if (hutk) {
      localStorage.setItem("hutk", hutk[1]);
    } else {
      console.warn("HubSpot hutk not found. Retrying in 1 second...");
      setTimeout(() => {
        hutk = document.cookie.match(/hubspotutk=([a-zA-Z0-9-_]+);?/);
        if (hutk) {
          console.log("Captured hutk after retry:", hutk[1]);
          localStorage.setItem("hutk", hutk[1]);
        }
      }, 1000);
    }

    this.applyStoredUTMParamsToForm();
  }

  // Apply stored UTM parameters to form fields
  applyStoredUTMParamsToForm() {
    const form = document.querySelector("#customHubspotForm");
    if (!form) return;

    ["utm_campaign", "utm_medium", "utm_source"].forEach(param => {
      const value = localStorage.getItem(param) || "";
      let input = form.querySelector(`input[name='${param}__c']`);

      if (!input) {
        input = document.createElement("input");
        input.type = "hidden";
        input.name = `${param}__c`;
        form.appendChild(input);
      }

      input.value = value;
    });

    console.log("✅ UTM Parameters applied to form:", {
      utm_campaign: form.querySelector("input[name='utm_campaign__c']").value,
      utm_medium: form.querySelector("input[name='utm_medium__c']").value,
      utm_source: form.querySelector("input[name='utm_source__c']").value,
    });
  }

  bindSectionButtons() {
    document.querySelectorAll('[data-action="click->request-modal#open"][data-section]').forEach(button => {
      button.addEventListener('click', () => {
        this.clearAllCheckboxes();
        const section = button.getAttribute('data-section');

        setTimeout(() => {
          if (section === "All") {
            this.checkAllCheckboxes();
          } else {
            const checkbox = document.querySelector(`input[name="product_interest[]"][value="${section}"]`);
            if (checkbox) {
              checkbox.checked = true;
              this.triggerChangeEvent(checkbox);
            }
          }
        }, 500);
      });
    });
  }

  // Trigger a change event for checkboxes
  triggerChangeEvent(element) {
    const event = new Event('change', { bubbles: true });
    element.dispatchEvent(event);
  }

  // Clear all checkboxes
  clearAllCheckboxes() {
    document.querySelectorAll('input[name="product_interest[]"]').forEach(checkbox => {
      checkbox.checked = false;
      this.triggerChangeEvent(checkbox);
    });
  }

  // Check all checkboxes
  checkAllCheckboxes() {
    document.querySelectorAll('input[name="product_interest[]"]').forEach(checkbox => {
      checkbox.checked = true;
      this.triggerChangeEvent(checkbox);
    });
  }

  // When the form is submitted, ensure the UTM parameters are present
  async submitForm(event) {
    event.preventDefault();
    const form = event.target;

    if (!this.isAtLeastOneChecked()) {
      this.showErrorMessage();
      return;
    }

    this.hideErrorMessage();

    try {
      const recaptchaToken = await grecaptcha.execute(window.recaptchaSiteKey, { action: "submit_pricing_form" });
      form.querySelector("#recaptchaToken").value = recaptchaToken;

      let hutk = localStorage.getItem("hutk") || "";

      const formData = new FormData(form);
      formData.append("pageUri", window.location.href);
      formData.append("pageName", document.title);
      formData.append("hutk", hutk);

      // 🔹 Explicitly attach UTM parameters
      ["utm_campaign", "utm_medium", "utm_source"].forEach(param => {
        let value = localStorage.getItem(param) || "";
        formData.append(`${param}__c`, value);
      });

      console.log("📢 Submitting form with data:", Object.fromEntries(formData));

      const response = await fetch(form.action, {
        method: "POST",
        headers: { "X-CSRF-Token": formData.get("authenticity_token") },
        body: formData,
      });

      if (!response.ok) throw new Error("Form submission failed.");

      const result = await response.json();
      if (result.status === "ok") {
        console.log("✅ Form submitted successfully. Logging HubSpot page view...");
        this.logHubSpotPageView();
        this.loadRevenueHeroWidget(result.hero_data.router_id, result.hero_data.fields);
      } else {
        console.error(result.message);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  }

  logHubSpotPageView() {
    if (typeof window._hsq === "undefined") {
      console.warn("HubSpot tracking script not loaded yet. Retrying in 500ms...");
      setTimeout(() => this.logHubSpotPageView(), 500);
      return;
    }

    console.log("Logging HubSpot Page View...");
    window._hsq.push(["trackPageView"]);
  }

  toggleSelectAll() {
    this.allSelected = !this.allSelected;
    this.productCheckboxTargets.forEach(checkbox => {
      checkbox.checked = this.allSelected;
    });

    this.selectAllButtonTarget.textContent = this.allSelected ? 'Deselect All' : 'Select All';
  }

  isAtLeastOneChecked() {
    return this.productCheckboxTargets.some(checkbox => checkbox.checked);
  }

  showErrorMessage() {
    this.errorContainerTarget.classList.remove("hidden");
  }

  hideErrorMessage() {
    this.errorContainerTarget.classList.add("hidden");
  }

  loadRevenueHeroWidget(routerId, fields) {
    const hero = new RevenueHero({ routerId, showLoader: true });
    hero.submit(fields)
      .then(rhData => hero.dialog.open(rhData))
      .catch(error => console.error("Error with RevenueHero:", error));
  }
}